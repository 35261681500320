<template>
  <!-- <professionals-card :card="{{ cardData|json_encode }}"></professionals-card> -->
  <div
    class="flex flex-wrap h-full p-8 bg-white shadow-md border-b-4 border-blue-light"
  >
    <div class="w-full md:w-1/5 md:border-r border-gray-100 pr-5">
      <img class="w-1/5" :src="card.logo[0].url" alt="" />
    </div>
    <div class="pl-5">
      <h1 class="text-lg">{{ card.heading }}</h1>
      <ul v-for="item in card.qualifications" :key="item">
        <li class="flex gap-2">
          <font-awesome-icon
            class="self-center text-blue-light"
            icon="arrow-left"
          ></font-awesome-icon>
          {{ item.qualifications }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfessionalsCard",
  props: ["card"],
};
</script>

<style scoped></style>
